import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { BsCheckCircle, BsBag, BsPeople } from "react-icons/bs"
import * as TIM_MAYAN_ORANGE from "../img/timmayanorange.jpg"
import * as TIM_MAYAN_NAVY from "../img/timmayannavy.jpg"
import * as TIM_STRIPY from "../img/timstripy.jpg"
import * as SHOWCASE_1 from "../img/bg-showcase-1.jpg"
import * as SHOWCASE_2 from "../img/bg-showcase-2.jpg"
import * as SHOWCASE_3 from "../img/bg-showcase-3.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Sprancer" />
    {/* Masthead */}
    <header className="masthead text-white text-center">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <h1
              className="mb-5"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              Messaging for local businesses and their customers.
            </h1>
          </div>
        </div>
      </div>
    </header>

    {/* Icons Grid */}
    <section className="features-icons bg-light text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <div className="features-icons-icon d-flex">
                <BsBag className="m-auto text-primary" />
              </div>
              <h3>Businesses</h3>
              <p className="lead mb-0">
                Your customers want to love you. Give them a safe way to connect
                and let them help you grow your community and your business.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <div className="features-icons-icon d-flex">
                <BsPeople className="m-auto text-primary" />
              </div>
              <h3>Customers</h3>
              <p className="lead mb-0">
                Local business are the heart of your community. Connect with
                them on your terms. No need to give out your email, phone
                number, or social media info.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="features-icons-item mx-auto mb-0 mb-lg-3">
              <div className="features-icons-icon d-flex">
                <BsCheckCircle className="m-auto text-primary" />
              </div>
              <h3>Sprancer</h3>
              <p className="lead mb-0">
                Our app gives businesses and their customers a simple, safe way
                to connect and communicate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Image Showcases */}
    <section className="showcase">
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div
            className="col-lg-6 order-lg-2 text-white showcase-img"
            style={{ backgroundImage: `url('${SHOWCASE_1}')` }}
          />
          <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            <h2>Dedicated Messaging</h2>
            <p className="lead mb-0">
              Emails get lost in the clutter. People only swipe through Facebook
              when they're bored. Sprancer gives customers a place to message
              with the businesses they care about. Customizable notifications
              let users give their attention when they're ready.
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div
            className="col-lg-6 text-white showcase-img"
            style={{
              backgroundImage: `url('${SHOWCASE_2}')`,
              backgroundPosition: "center",
            }}
          />
          <div className="col-lg-6 my-auto showcase-text">
            <h2>Relevant Communication</h2>
            <p className="lead">
              Local businesses know their customers. With Sprancer, businesses
              group people by interest to send them relevant messages. Customers
              can easily start private conversations when they want to learn
              more.
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div
            className="col-lg-6 order-lg-2 text-white showcase-img"
            style={{
              backgroundImage: `url('${SHOWCASE_3}')`,
              backgroundPosition: "center",
            }}
          />
          <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            <h2>Trust and Control</h2>
            <p className="lead mb-0">
              When connecting through Sprancer, customers keep their contact
              information private. It's easy for someone to make a new
              connection when they know they have the control to turn it down or
              turn it off.
            </p>
          </div>
        </div>
      </div>
    </section>

    {/* Testimonials */}
    <section className="testimonials text-center bg-light">
      <div className="container">
        <h2 className="mb-5">Meet The Team</h2>
        <div className="row">
          <div className="col-lg-4">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid rounded-circle mb-3"
                src={TIM_MAYAN_ORANGE}
                alt=""
              />
              <h5>Tim McFadden</h5>
              <h7>Founder</h7>
              <p className="font-weight-light mb-0">
                "The software available to businesses is amazing, but most of it
                is too complex to be useful to small teams. At Sprancer we're
                building simple solutions for small business owners."
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid rounded-circle mb-3"
                src={TIM_MAYAN_NAVY}
                alt=""
              />
              <h5>Tim McFadden</h5>
              <h7>CTO</h7>
              <p className="font-weight-light mb-0">
                "Local business owners are implicitly the leader of a community
                of their customers. Sprancer is designed to help build and grow
                that community."
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
              <img
                className="img-fluid rounded-circle mb-3"
                src={TIM_STRIPY}
                alt=""
              />
              <h5>Tim McFadden</h5>
              <h7>Lead Engineer</h7>
              <p className="font-weight-light mb-0">
                "Cloud computing has changed the economics of software. Sprancer
                is built specifically for small teams which lets us offer our
                products at a price that makes sense for small business owners."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Call to Action */}
    <section className="call-to-action text-white text-center">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <h2 className="mb-4">Want to learn more?</h2>
            <p>
              Please fill out the form and we will get back to you as soon as
              possible.
            </p>
          </div>
          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
            <form
              name="learnMore"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="learnMore" />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      required="required"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      required="required"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  required
                />
                <p className="help-block text-danger"></p>
              </div>
              <div id="success"></div>
              <button
                type="submit"
                className="btn btn-block btn-lg btn-primary"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
